
import { defineComponent } from 'vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { scrollToTop } from './../../utils/helper';
import emailjs from 'emailjs-com';

export default defineComponent({
  components: {},
  data() {
    return {};
  },
  created() {
    scrollToTop();
  },
  setup() {
    const { t } = useI18n();
    const form = ref(null);

    const sendMail = () => {
      emailjs
        .sendForm(
          'service_n27tv1y',
          'template_e2945sn',
          form.value || 'form value is empty',
          'gtumohHMFYxGd0BQ2',
        )
        .then(
          () => {
            alert('Message sent!');
          },
          (error) => {
            alert('Message not sent' + error);
            console.log('Message not sent' + error);
          },
        );
    };

    return {
      t,
      form,
      sendMail,
    };
  },
});
