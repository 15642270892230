import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "contact-us" }
const _hoisted_2 = { class: "col-12 d-flex justify-content-center form-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_form_group = _resolveComponent("b-form-group")!
  const _component_b_form_textarea = _resolveComponent("b-form-textarea")!
  const _component_b_button = _resolveComponent("b-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", {
        style: {"display":"flex","flex-direction":"column"},
        ref: "form",
        onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.sendMail && _ctx.sendMail(...args)), ["prevent"]))
      }, [
        _createVNode(_component_b_form_group, {
          id: "input-group-2",
          label: _ctx.t('Name'),
          "label-for": "Name",
          class: "animate__animated animate__fadeInUp"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_form_input, {
              id: "Name",
              name: "from_name",
              placeholder: _ctx.t('Name')
            }, null, 8, ["placeholder"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_b_form_group, {
          id: "input-group-1",
          label: _ctx.t('Email'),
          "label-for": "Email",
          class: "animate__animated animate__fadeInUp"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_form_input, {
              id: "Email",
              name: "email",
              type: "email",
              placeholder: _ctx.t('Email'),
              required: ""
            }, null, 8, ["placeholder"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_b_form_textarea, {
          id: "textarea",
          name: "message",
          placeholder: _ctx.t('EnterSomething'),
          rows: "6",
          "max-rows": "10",
          class: "animate__animated animate__fadeInUp"
        }, null, 8, ["placeholder"]),
        _createVNode(_component_b_button, {
          type: "submit",
          variant: "primary",
          class: "animate__animated animate__fadeInUp"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('Send')), 1)
          ]),
          _: 1
        })
      ], 544)
    ])
  ]))
}